<template>
    <v-stepper v-if="expanded" v-model="currentStep">
        <v-stepper-header>
            <template
                v-for="n in stepsCount"            
            >
                <v-stepper-step :key="`step_${n}`" :step="n" editable />
                <v-divider :key="`divider_${n}`" v-if="n < stepsCount" />
            </template>
        </v-stepper-header>

        <v-stepper-items>
            <v-stepper-content step="1">
                <div class="d-flex align-center mb-4">
                    <v-spacer />
                    <v-btn v-if="this.currentStep < this.stepsCount" dark color="purple darken-2" small @click="next">
                        Дальше
                    </v-btn>
                </div>
                <p>Для получения форм сбора результатов Вам необходимо скачать программу Адаптер ФГИС ФИС ОКО из <a href="https://lk-fisoko.obrnadzor.gov.ru/" target="_blank">Личного кабинета ФИС ОКО</a>.</p>
            </v-stepper-content>
            <v-stepper-content step="2">
                <div class="d-flex align-center mb-4">
                    <v-btn v-if="this.currentStep > 1" dark color="purple darken-2" @click="back">
                        Назад
                    </v-btn>
                    <v-spacer />
                    <v-btn v-if="this.currentStep < this.stepsCount" dark color="purple darken-2" @click="next">
                        Дальше
                    </v-btn>
                </div>
                <p>Для установки приложения запустите скачанный на предыдущем шаге файл и следуйте инструкциям мастера установки.</p>
                <p>После завершения установки на Рабочем столе появится ярлык «Адаптер». Используйте его для запуска приложения.</p>
            </v-stepper-content>
            <v-stepper-content step="3">
                <div class="d-flex align-center mb-4">
                    <v-btn v-if="this.currentStep > 1" dark color="purple darken-2" @click="back">
                        Назад
                    </v-btn>
                    <v-spacer />
                    <v-btn v-if="this.currentStep < this.stepsCount" dark color="purple darken-2" @click="next">
                        Дальше
                    </v-btn>
                </div>
                <p>После запуска приложения Вам нужно будет ввести логин и пароль от системы ФГИС ФИС ОКО для продолжения работы.</p>
                <img :src="images[0]" class="mx-auto d-flex" />
            </v-stepper-content>
            <v-stepper-content step="4">
                <div class="d-flex align-center mb-4">
                    <v-btn v-if="this.currentStep > 1" dark color="purple darken-2" @click="back">
                        Назад
                    </v-btn>
                    <v-spacer />
                    <v-btn v-if="this.currentStep < this.stepsCount" dark color="purple darken-2" @click="next">
                        Дальше
                    </v-btn>
                </div>
                <p>Главное окно приложения отображает список доступных для Вас отчётов, статус отчёта в системе ФГИС ФИС ОКО и кнопки для скачивания шаблона отчета.</p>
                <img :src="images[1]" class="mx-auto d-flex" />
            </v-stepper-content>
            <v-stepper-content step="5">
                <div class="d-flex align-center mb-4">
                    <v-btn v-if="this.currentStep > 1" dark color="purple darken-2" @click="back">
                        Назад
                    </v-btn>
                    <v-spacer />
                    <v-btn v-if="this.currentStep < this.stepsCount" dark color="purple darken-2" @click="next">
                        Дальше
                    </v-btn>
                </div>
                <p>Нажмите на кнопку <img :src="images[2]" class="mb-n2" /> и выберите формат json.</p>
                <img :src="images[3]" class="mx-auto d-flex mb-4" />
                <p>Для получения шаблона для заполнения выбранного отчёта нажмите кнопку «Скачать ↑» в строке напротив отчёта. Выберите формат скачиваемого файла json.
                    В появившемся диалоге сохранения файла шаблона подтвердите сохранение файла в удобное Вам место.</p>
            </v-stepper-content>
            <v-stepper-content step="6">
                <div class="d-flex align-center mb-4">
                    <v-btn v-if="this.currentStep > 1" dark color="purple darken-2" @click="back">
                        Назад
                    </v-btn>
                    <v-spacer />
                </div>
                <p>Нажмите на кнопку «Импорт процедуры» под этой инструкцией и выберите файл, сохраненный на предыдущем шаге в открывшемся окне. После добавления появится процедура, для ее заполнения нажмите на <img :src="images[4]" class="mb-n3" /></p>
            </v-stepper-content>
            <a class="d-flex pa-4" @click.prevent.stop="$emit('update:expanded', false)">Скрыть</a>
        </v-stepper-items>
    </v-stepper>
    <div v-else>
        <p>Для получения форм сбора результатов Вам необходимо скачать программу Адаптер ФГИС ФИС ОКО из <a href="https://lk-fisoko.obrnadzor.gov.ru/" target="_blank">Личного кабинета ФИС ОКО</a>.</p>
        <a @click.prevent.stop="$emit('update:expanded', true)">Подробнее...</a>
    </div>
</template>

<script>
import image1 from '@/assets/img/fisoko-info/1.png'
import image2 from '@/assets/img/fisoko-info/2.png'
import image3 from '@/assets/img/fisoko-info/3.png'
import image4 from '@/assets/img/fisoko-info/4.png'
import image5 from '@/assets/img/fisoko-info/5.png'

export default {
    props: {
        expanded: { type: Boolean, default: false }
    },
    data () {
        return {
            currentStep: 1,
            stepsCount: 6
        }
    },
    computed: {
        images () {
            return [image1, image2, image3, image4, image5]
        }
    },
    methods: {
        next () {
            if (this.currentStep < this.stepsCount)
                this.currentStep++
        },
        back () {
            if (this.currentStep > 1)
                this.currentStep--
        }
    }
}
</script>