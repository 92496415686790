<template>
    <div>
        <div class="text-h6">Формы сбора данных ФИС ОКО</div>

        <fioko-data-collection-instruction class="mt-5" :expanded.sync="fiokoDataCollectionInstructionExpanded" />
            
        <grid-view
            ref="grid"
            :headers="headers"
            store-module="fioko_data_collection"
            url-update="/fioko-data-collection/update/:id"
            filter
            class="mt-5"
            @fetched="onItemsFetched"
        >
            <!-- Filter -->
            <template v-slot:filter="{ filter }">
                <v-row>
                    <v-col lg="6" sm="12">
                        <v-select
                            v-model="filter.subject"
                            :items="subjects"
                            :menu-props="{ maxHeight: '500' }"
                            label="Предмет"
                            color="purple darken-2"
                        />
                    </v-col>

                    <v-col lg="6" sm="12">
                        <v-select
                            v-model="filter.grade"
                            :items="grades"
                            :menu-props="{ maxHeight: '500' }"
                            label="Параллель"
                            color="purple darken-2"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col lg="6" sm="12">
                        <v-select
                            v-model="filter.year"
                            :items="years"
                            :menu-props="{ maxHeight: '500' }"
                            label="Год"
                            color="purple darken-2"
                        />
                    </v-col>
                </v-row>
            </template>
            <!-- END Filter -->
            
            <template #[`title.right`]>
                <div class="d-flex align-center">
                    <fioko-data-collection-import-form
                        v-if="isCanEditFiokoDataCollectionSection"
                        hint
                        class="mr-4"
                        @sent="onFileSent"
                    />
                    <v-btn
                        v-if="isCanEditFiokoDataCollectionSection"
                        dark
                        color="purple darken-2"
                        small
                        @click="dialogs.downloadReports = true"
                    >Экспорт процедуры</v-btn>
                </div>
            </template>
        </grid-view>

        <v-dialog v-model="dialogs.downloadReports" max-width="600px">
            <v-card>
                <v-toolbar dark color="purple darken-2">
                    <v-toolbar-title>Экспорт процедуры</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <p class="mt-4">Выберите отчёт для скачивания</p>
                    <fioko-data-collection-export-all-as-json-form />
                </v-card-text>
                <v-card-actions>
                    <v-btn outlined color="warning" @click.prevent.stop="dialogs.downloadReports = false">
                        Закрыть
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GridView from '@/components/crud/GridView.vue'
import FiokoDataCollectionExportAllAsJsonForm from '@/components/forms/FiokoDataCollectionExportAllAsJsonForm.vue'
import FiokoDataCollectionImportForm from '@/components/forms/FiokoDataCollectionImportForm'
import isUserCanMixin from '@/mixins/isUserCanMixin'
import FiokoDataCollectionInstruction from '@/components/fioko-data-collection/Instruction.vue'

export default {
    name: 'FiokoIndex',
    mixins: [isUserCanMixin],
    components: {
        GridView, FiokoDataCollectionInstruction,
        FiokoDataCollectionImportForm, FiokoDataCollectionExportAllAsJsonForm
    }, 
    data () {
        return {
            fiokoDataCollectionInstructionExpanded: false,
            dialogs: {
                downloadReports: false,
                downloadApprovement: false
            }
        }
    },
    computed: {
        subjects () { return this.$store.getters['calendar_event/subjects'] },
        grades () {
            const grades = [4,5,6,7,8,10]
            return grades.map(grade => ({ text: `${grade} класс`, value: grade }))
        },
        years () {
            const years = []
            const minYear = 2023
            const curMonth = (new Date()).getMonth() + 1
            let currentYear = (new Date()).getFullYear()
   
            if (curMonth < 9)
                currentYear--
            
            for (let year = currentYear; year >= minYear; year--) {
                years.push(year)
            }
            return years
        },
        headers () {
            return [
                // { text: 'Активность', value: 'active', width: 1, sortable: false },
                { text: '', value: 'edit', width: 1, sortable: false },
                { text: 'Название', value: 'name' },
                { text: 'Предмет', value: 'subject' },
                { text: 'Параллель', value: 'grade' },
                { text: 'Год', value: 'year' },
                { text: 'Статус', value: 'status_formatted' },
                // { text: 'Сформированный отчёт', value: 'generated_file_path' },
                { text: '', value: 'edit', width: 1, sortable: false }
            ]
        }
    },
    methods: {
        onFileSent ({ success, errors }) {

            if (success) {

                this.$root.$emit('snack-bar', { text: 'Данные успешно импортированы' })
                return this.$refs.grid?.fetch?.()
            }

            let message = 'Импорт прерван неизвестной ошибкой'
            if (Array.isArray(errors)) {
                message = errors[0]
            } else if (typeof errors === 'object') {
                const keys = Object.keys(errors)
                message = Array.isArray(errors[keys[0]]) ? errors[keys[0]][0] : errors[keys[0]]
            }

            this.$root.$emit('snack-bar', { text: `Ошибка: ${message}` })
        },
        onItemsFetched ({ total }) {
            if (total === 0)
                this.fiokoDataCollectionInstructionExpanded = true
        }
    }
}
</script>