<template>
    <div>
        <v-alert
            v-if="!_.isNil(error)"
            dense
            type="error"
        >
            {{ error }}
        </v-alert>

        <v-data-table
            :loading="loading"
            dense
            :items-per-page="-1"
            hide-default-footer
            culate-widths="true"
            :headers="[{ text: 'Название', value: 'name' }, { text: 'Скачать', value: 'download' }]"
            :items="models"
            item-key="id"
            class="elevation-1"
        >
            <template #[`item.download`]="{ item }">
                <v-icon
                    @click="saveAsJSONHandler(item)"
                >mdi-download</v-icon>
            </template> 
        </v-data-table>
    </div>
</template>

<script>
import FiokoDataCollectionMixin from '@/mixins/FiokoDataCollectionMixin'

 export default {
    mixins: [FiokoDataCollectionMixin],
    data () {
        return {
            models: [],
            error: null,
            loading: false
        }
    },
    created () {
        this.init()
    },
    methods: {
        async init () {
            try {
                this.models = []
                this.error = null
                this.loading = true
                const reports = await this.retrieveReports()
                const forms = await this.retrieveDataCollections(reports.map(r => r.data_collection_id))
                forms.forEach(form => {
                    form.reports = reports.filter(r => r.data_collection_id === form.data_collection_id)
                })
                this.models = forms
            } finally {
                this.loading = false
            }
        },
        async retrieveReports () {
            let result = []
            try {
                const { success, error, data } = await this.$store.dispatch('fioko_report/list', {
                    fields: ['value', 'data_collection_id', 'status'].join(','),
                    filter: { status: 'done' }
                })

                if (!success)
                    throw new Error(error)
                
                result = data?.items || []
            } catch (e) {
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
                throw new Error(e)
            }
            return result
        },
        async retrieveDataCollections (data_collection_id) {
            if (!data_collection_id)
                throw new Error(`data_collection_id data not provided`)

            let result = []
            try {
                const { success, error, data } = await this.$store.dispatch('fioko_data_collection/list', {
                    fields: ['name', 'data_collection_id', 'download_approved'].join(','),
                    filter: { data_collection_id: { in: data_collection_id } }
                })

                if (!success)
                    throw new Error(error)
                
                result = data?.items || []
            } catch (e) {
                this.error = e.message || 'Неизвестная ошибка загрузки данных'
                throw new Error(e)
            }
            return result
        },
        saveAsJSONHandler (dataCollection) {
            this.exportToJson(dataCollection.reports, dataCollection.name)
        }
    }
 }

</script>